<template>
    <div v-if="display" class="alert text-center" :class="type" style="position: fixed; top: 55px; z-index: 9999; left: 0px; width: 100%;">
        {{ msg }}
    </div>
</template>

<script>

    export default {

        data() {
          return {
              msg: '',
              type: '',
              duration: 3000 // default 3 secs
          }
        },

        computed: {
            display() {
                return this.msg && this.type;
            }
        },

        methods: {
            toggle(msg, type, duration) {
                this.msg = msg;
                this.type = type;
                setTimeout(() => {
                    this.msg = '';
                    this.type = '';
                }, (duration ? duration : this.duration));
            }
        }
    }

</script>
