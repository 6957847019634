<template>
    <span
        v-show="$parent.errors.has(name, scope)"
        class="help-block"
    >
        {{ $parent.errors.first(name,scope) }}
    </span>
</template>

<script>
    export default {
        props: {
          'name': {
            required: true
          },
          'scope': {
            required: false
          }
        },
    }
</script>
